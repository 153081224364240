$green: #78c2ad;
$cyan: #395C6B;
$red: #f87c53;
$white: #f8f9fa;
$primary: $green;
$primary-border-regular: 1px solid $green;

.temp-wrapper {
  width: 390px;
  height: 844px;
  background-size: cover;
  background-repeat: no-repeat;
}

.map-container {
  height: 100%;
  width: 100%;
}

.tx-6 {
  font-size: small;
}

.tx-5 {
  font-size: medium;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder input {
  width: 100%;
}


.form-control.is-invalid {
  padding-right: .75rem;
  background-image: none;
}

@import "~bootstrap/scss/bootstrap.scss";